import React, { useEffect, useState } from 'react'
import BottomMenu from '../components/BottomMenu'
import Swal from 'sweetalert2'

export default function DeleteAccount() {
  const [loading, setLoading] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: '',
        text: 'Your account will be deleted shortly',
        footer: 'Alert by the Demon Official Store team',
        confirmButtonColor: '#1c26b9',
      })
    }, 2000)
  }

  useEffect(() => {
    document.title = 'Demon Official Store - Profile'
    // eslint-disable-next-line
  }, [])

  return (
    <section className='wrapper wrapper-asidecontent'>
      <div className='container'>
        <div className='row pt-md-4 pt-3'>
          <div className='col pt-md-3 pt-1 profile-wrapper'>
            <h2 className='title'>Delete Account</h2>

            <form autoComplete='off' onSubmit={handleSubmit}>
              <div className='my-md-4 my-3'>
                <label htmlFor='Name' className='form-label input-label pb-md-1 mb-md-1 p-0 m-0'>
                  Name
                </label>
                <input type='text' autoComplete='off' name='name' placeholder='Your name' className='form-control form-control-lg input-box' id='Name' />
              </div>

              <div className='mb-md-4 mb-3'>
                <label htmlFor='Email' className='form-label input-label pb-md-1 mb-md-1 p-0 m-0'>
                  Email
                </label>
                <input type='email' autoComplete='off' name='email' placeholder='Your email address' id='Email' className='form-control form-control-lg input-box' />
              </div>

              <div className='mb-md-4 mb-3'>
                <label htmlFor='Number' className='form-label input-label pb-md-1 mb-md-1 p-0 m-0'>
                  Number
                </label>
                <input type='number' autoComplete='off' name='number' placeholder='Your mobile number' id='Number' className='form-control form-control-lg input-box' />
              </div>

              <div className='mb-md-4 mb-3'>
                <label htmlFor='Number' className='form-label input-label pb-md-1 mb-md-1 p-0 m-0'>
                  Message
                </label>
                {/* <input type='number' autoComplete='off' name='number' placeholder='Your mobile number' id='Number' className='form-control form-control-lg input-box' /> */}
                <textarea autoComplete='off' name='message' placeholder='Your message' id='message' className='form-control form-control-lg' rows={4} defaultValue={''} />
              </div>

              <div className='pt-2 pb-3'>
                <button type='submit' disabled={loading} className='btn btn-pay'>
                  {loading ? (
                    <>
                      <span className='spinner-border spinner-border-sm me-05' role='status' aria-hidden='true' />
                      &nbsp; Loading...
                    </>
                  ) : (
                    'Continue'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        <BottomMenu />
      </div>
    </section>
  )
}
